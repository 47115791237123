import {useMemo, useState, useCallback, useEffect, useRef, Profiler} from "react";
import { useDebounce } from 'usehooks-ts';
import { uuidv7 } from "uuidv7";
import * as DOMPurify from 'dompurify';
import emojiData from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { Popover } from 'react-tiny-popover';
import { confirmDialog } from 'primereact/confirmdialog';

import Modal from 'react-bootstrap/Modal';
import { Button, AddUserButton, ArrowButton, SendButton, MainContainer, Sidebar, ConversationList, Conversation, Avatar, ChatContainer, ConversationHeader, MessageGroup, Message,MessageList, MessageInput, TypingIndicator, Search, InputToolbox } from "@chatscope/chat-ui-kit-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSlash, height } from "@fortawesome/free-solid-svg-icons/faPhoneSlash";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons/faPhoneVolume";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons/faPhoneAlt";
import { faVideo } from "@fortawesome/free-solid-svg-icons/faVideo";
import { faDesktop } from "@fortawesome/free-solid-svg-icons/faDesktop";
import { fa0, fa1, fa2, fa3, fa4, fa5, fa6, fa7, fa8, fa9, faStarOfLife, faHashtag, faXmark, faHouse, faMagnifyingGlassArrowRight, faRightFromBracket, faPenToSquare, faReply, faFaceSmile } from "@fortawesome/free-solid-svg-icons";

import {
    useChat,
    ChatMessage,
    MessageContentType,
    MessageDirection,
    MessageStatus,
    MessageGroup as MessageGroupData,
    Sender,
    SenderType,
    UserStatus,
} from "@chatscope/use-chat";
import {MessageContent, TextContent, User} from "@chatscope/use-chat";
import {SnikketChatService} from "../SnikketChatService";
import {AvatarWithPlaceholder} from "./AvatarWithPlaceholder";
import Fuse from 'fuse.js';
import { Virtuoso } from 'react-virtuoso'

import * as snikket from "snikket-sdk";

snikket.Config.relativeHashUri = true;
DOMPurify.setConfig({FORBID_TAGS: ['style']});

export const Chat = ({persistence}:{persistence:any}) => {
    // Get all chat related values and methods from useChat hook
    const {
        currentMessages, conversations, activeConversation, setActiveConversation,  sendMessage, getUser, currentMessage, setCurrentMessage,
        sendTyping, setCurrentUser, service, removeMessagesFromConversation, getConversation, currentUser
    } = useChat();

    const totalUnread = conversations.map(c => c.unreadCounter).reduce((x, y) => x + y, 0);
    useEffect(() => {
        console.log("UNREAD", totalUnread);
        const icon = document.querySelector("link[rel*=icon]") as HTMLLinkElement;
        if (totalUnread < 1) {
            icon.href = "/favicon.ico";
        } else {
            const img = new Image();
            img.addEventListener("load", () => {
                const canvas = document.createElement('canvas');
                canvas.height = 64;
                canvas.width = 64;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, 64, 64);
                ctx.fillStyle = "red";
                ctx.arc(64-17, 17, 17, 0, 2 * Math.PI, false);
                ctx.fill();
                ctx.font = "32px sans-serif";
                ctx.fillStyle = "white";
                ctx.textBaseline = "top";
                ctx.fillText("" + totalUnread, 64-28, 2, 26);
                icon.href = canvas.toDataURL();
            });
            img.src = icon.href;
        }
    }, [totalUnread]);

    const snikketService = service as SnikketChatService;

    const [audioTracks, setAudioTracks] = useState([]);
    useEffect(() => {
        snikketService.on("callAudioTrack", (data: any) => {
            setAudioTracks(audioTracks.concat(data));
            data.track.addEventListener("ended", (event) => {
                setAudioTracks(audioTracks.filter((item) => item.track.id === data.track.id));
            });
        });

        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener("message", (event) => {
                if (event.data.event === "notificationclick") {
                    setActiveConversation(event.data.data.chatId);
                }
            });
        }
    }, []);

    const messageList = useRef(null);
    const previousConversation = useRef(null);
    const [reactionPopovers, setReactionPopovers] = useState({});
    const [showSidebar, setShowSidebar] = useState(true);
    const [replyTo, setReplyTo] = useState(null);
    const [editing, setEditing] = useState(null);
    const [searchMessages, setSearchMessages] = useState(false);
    const [searchMessageResults, setSearchMessageResults] = useState([]);

    // When conversation has changed
    useEffect(() => {
        if (activeConversation && (currentMessages.length < 1 || (activeConversation.data?.loadedToEnd && currentMessages.length < 50))) {
            snikketService.loadMessagesBefore(activeConversation.id);
        }
        if (previousConversation.current && activeConversation?.id !== previousConversation.current.id) {
           const prevData = previousConversation.current.data || {};
           prevData?.chat?.setActive(false, prevData?.currentThread);
           if ((Number.MAX_SAFE_INTEGER - (prevData?.scrollRange?.endIndex || 0) < 2)) {
               delete prevData.scrollRange;
               prevData.loadedToEnd = false;
               removeMessagesFromConversation(previousConversation.current.id);
           }
        }
        activeConversation?.data?.chat?.setActive(true, activeConversation?.data?.currentThread);
        setReactionPopovers({});
        if (editing) {
            clearAttachments();
            setCurrentMessage("");
            setEditing(null);
        }
        setSearchMessages(false);
        setReplyTo(null);
        setSearchMessages(false);
        setSearchMessageResults([]);
        setShowSidebar(false);
        previousConversation.current = activeConversation;
    }, [activeConversation]);

    let jumpTo = null;
    if (activeConversation && activeConversation?.data?.jumpToIndex && messageList.current) {
      jumpTo = activeConversation.data.jumpToIndex;
      setTimeout(() => delete activeConversation.data.jumpToIndex, 500);
      messageList.current.scrollToIndex({ index: jumpTo });
    }

    const inputRef = useRef();
    const [search, setSearch] = useState("");
    const debouncedSearch = useDebounce(search, 300);
    const [availableChats, setAvailableChats] = useState({ q: "", chats: [] });
    const [showDtmf, setShowDtmf] = useState(false);
    const [currentThread, setCurrentThread] = useState({});
    const toolbarRefs = useRef({});

    const logout = () => {
        confirmDialog({
            message: "Do you want to also delete all chats and messages from this device?",
            acceptClassName: 'p-button-danger',
            defaultFocus: "reject",
            accept: () => snikketService.logout(true),
            reject: () => snikketService.logout(false),
        });
    };

    const setSelectedThread = (threadId: string) => {
        if (!activeConversation || !activeConversation.data) return;
        activeConversation.data.currentThread = threadId;
        activeConversation.data?.chat?.setActive(true, activeConversation.data?.currentThread);
        setCurrentThread({ ...currentThread, [activeConversation.id]: threadId });
    };

   const getTypingIndicator = () => {
       if (activeConversation) {
           return activeConversation.typingUsers.items.map((typingUser) => {
               const typingUserId = typingUser.userId;
               // Check if typing user participates in the conversation
               if (typingUser.isTyping && activeConversation.participantExists(typingUserId)) {
                   const typingUserG = getUser(typingUserId);
                   if (typingUserG) {
                       return <TypingIndicator content={`${typingUserG.username} is typing`} />
                   }
               }
           });
       }

       return [];
   };

    const onYReachStart = () => {
        if (!activeConversation) return;

        snikketService.loadMessagesBefore(activeConversation.id);
    };

    const onYReachEnd = () => {
        if (!activeConversation) return;

        snikketService.loadMessagesAfter(activeConversation.id);
    };

    const handleBackClick = () => {
        setShowSidebar(true);
    };

    const conversationInfo = (c) => {
        var info = c.data.chat.preview();
        if (c.draft) {
            const mkText = document.createElement("div");
            mkText.innerHTML = c.draft;
            info = "Draft: " + mkText.textContent;
        }
        if (c.data.chat.syncing()) {
            info = "Syncing...";
        }
        if (c.data.chat.callStatus() == "incoming") {
            info = "📲 " + info;
        }
        if (c.data.chat.callStatus() == "ongoing") {
            info = "📞 " + info;
        }
        if (c.typingUsers.items.filter(typingUser => typingUser.isTyping).length > 0) {
            info = <TypingIndicator content={info}/>;
        }

        return { fn: c.data.chat.getDisplayName(), photo: c.data.chat.getPhoto(), placeholder: c.data.chat.getPlaceholder(), info: info };
    };

    const conversationSearch = new Fuse(conversations, {
        useExtendedSearch: true,
        keys: [
            { name: "fn", weight: 2, getFn: (c) => c.data?.chat?.getDisplayName() },
            "id",
        ]
    });

    useEffect(() => {
        if (search) {
            if (searchMessages) {
                let results = [];
                persistence.searchMessages(null, null, search, (q, message) => {
                    if (!searchMessages || q !== search || !message) return false;
                    const m = snikketService.useMessage(message);
                    const group = new MessageGroupData({
                        id: snikketService.storage.groupIdGenerator(),
                        sender: m.sender,
                        direction: m.direction,
                    });
                    group.addMessage(m);
                    results = [group, ...results].sort((x, y) => x.messages[0].createdTime.getTime() - y.messages[0].createdTime.getTime());
                    setSearchMessageResults(results);
                    return true; // Continue searching
                });
            } else {
                snikketService.findAvailableChats(search, (q, chats) => {
                    if (search === q) setAvailableChats({ q: q, chats: chats });
                });
            }
        }
    }, [debouncedSearch, searchMessages]);

    const oneDtmf = (digit: string) => {
        const audioContext = new AudioContext();
        const duration = 500; // Duration in milliseconds

        const frequencies = {
            '1': [697, 1209],
            '2': [697, 1336],
            '3': [697, 1477],
            '4': [770, 1209],
            '5': [770, 1336],
            '6': [770, 1477],
            '7': [852, 1209],
            '8': [852, 1336],
            '9': [852, 1477],
            '*': [941, 1209],
            '0': [941, 1336],
            '#': [941, 1477],
        };

        const oscillator1 = audioContext.createOscillator();
        oscillator1.type = 'sine';
        oscillator1.frequency.setValueAtTime(frequencies[digit][0], audioContext.currentTime);

        const oscillator2 = audioContext.createOscillator();
        oscillator2.type = 'sine';
        oscillator2.frequency.setValueAtTime(frequencies[digit][1], audioContext.currentTime);

        const gainNode = audioContext.createGain();
        gainNode.gain.setValueAtTime(0.3, audioContext.currentTime);

        oscillator1.connect(gainNode);
        oscillator2.connect(gainNode);
        gainNode.connect(audioContext.destination);

        oscillator1.start();
        oscillator2.start();

        activeConversation.data.chat.dtmf().insertDTMF(digit, duration);

        setTimeout(() => {
            oscillator1.stop();
            oscillator2.stop();
            audioContext.close();
        }, duration);
    }

    const [playingRingback, setPlayingRingback] = useState(false);
    const playRingback = () => {
        if (!activeConversation || !activeConversation.data || activeConversation.data.chat.callStatus() !== "outgoing") {
            setPlayingRingback(false);
            return;
        }

        const audioContext = new AudioContext();
        const duration = 2000; // Duration in milliseconds for each tone
        const volume = 0.2; // Adjust the volume as needed

        // Define the frequencies for the ringback tone (e.g., 440Hz and 480Hz)
        const frequency1 = 440;
        const frequency2 = 480;

        const gainNode = audioContext.createGain();
        gainNode.gain.setValueAtTime(volume, audioContext.currentTime);

        const oscillator1 = audioContext.createOscillator();
        oscillator1.type = 'sine';
        oscillator1.frequency.setValueAtTime(frequency1, audioContext.currentTime);

        const oscillator2 = audioContext.createOscillator();
        oscillator2.type = 'sine';
        oscillator2.frequency.setValueAtTime(frequency2, audioContext.currentTime);

        oscillator1.connect(gainNode);
        oscillator2.connect(gainNode);
        gainNode.connect(audioContext.destination);

        // Start the oscillators
        oscillator1.start();
        oscillator2.start();

        // Stop the oscillators and close the audio context after the duration
        setTimeout(() => {
            oscillator1.stop();
            oscillator2.stop();
            audioContext.close();
        }, duration);

        setTimeout(() => {
            playRingback();
        }, duration*1.5);
    }

    const attachmentContent = (attachment, size?) => {
        if (attachment?.mime?.indexOf("image/") === 0) {
            return <div><a href={attachment.uris[0]}><img src={attachment.uris[0]} style={{height: (size || "10em")}} /></a></div>;
        } else if (attachment?.mime?.indexOf("audio/") === 0) {
            return <audio src={attachment.uris[0]} controls></audio>;
        } else if (attachment?.mime?.indexOf("video/") === 0) {
            return <video src={attachment.uris[0]} style={{maxHeight: size || "50vh"}} controls></video>;
        } else {
            return <a href={attachment.uris[0]}>attachment</a>;
        }
    };

    if (!playingRingback && activeConversation && activeConversation.data && activeConversation.data.chat.callStatus() === "outgoing") {
        setPlayingRingback(true);
        playRingback();
    }

    const activeConversationInfo = activeConversation && conversationInfo(activeConversation);

    if (activeConversation && !document.hidden) {
        const mostRecentMessage = currentMessages.at(-1)?.messages.at(-1)?.data;
        if (mostRecentMessage !== null && mostRecentMessage !== undefined) {
            setTimeout(() => activeConversation.data.chat.markReadUpTo(mostRecentMessage), 0);
        }
    }

    const displayMessageGroups = searchMessages ? searchMessageResults : currentMessages;

    const statusStrings = {
        [MessageStatus.Pending]: "Waiting...",
        [MessageStatus.Sent]: "Sending...",
        [MessageStatus.DeliveredToCloud]: "Sent",
        [MessageStatus.DeliveredToDevice]: "Received",
    };

    function escapeHtml(unsafe) {
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;");
    }

    const avatarClick = (group: MessageGroupData) => {
        if (!activeConversation) return;
        const user = getUser(group.sender?.id);
        setSelectedThread((group.messages[0]?.data as any)?.threadId);
        const matchData = currentMessage.match(/^([^:]+):/);
        if (matchData) {
            setCurrentMessage(matchData[1] + ", <span class='h-card'>" + escapeHtml(user.username) + "</span>: " + currentMessage.replace(/^[^:]+:/, ""));
        } else {
            setCurrentMessage("<span class='h-card'>" + escapeHtml(user.username) + "</span>: " + currentMessage);
        }
        (inputRef.current as any)?.focus();
    };

    const [attachments, setAttachments] = useState({});
    const handleChange = (innerHTML:string, textContent:string, innerText:string, content:NodeList) => {
        // NOTE: innerHTML sometimes has trailing <br> or is just <br> when it should be blank
        // Send typing indicator to the active conversation
        // You can call this method on each onChange event
        // because sendTyping method can throttle sending this event
        // So typing event will not be send to often to the server
        // NOTE: \u002b is a silly hack to work around https://github.com/chatscope/chat-ui-kit-react/issues/136
        const conversation = activeConversation?.id;
        let html = "";
        content.forEach(el => {
            // TODO: This is fine for paste of single image, but paste/drop of more complex
            // with nested images is possible and this won't handle that
            if (el instanceof HTMLImageElement) {
                (async function() {
                    let attachment;
                    if (el.src.startsWith("http")) {
                        let mime = "image/*";
                        let size = null;
                        try {
                            // Unfortunately this requires CORS and there is no way to get this data from the HTMLImageElement
                            // We also don't compute hashes, which we could if the fetch works but otherwise can't
                            const response = await fetch(el.src, { method: "HEAD" });
                            mime = response.headers.get("content-type");
                            size = response.headers.get("content-length");
                        } catch(e) { }
                        attachment = new snikket.ChatAttachment(el.src.split("/").pop(), mime, size, [el.src], []);
                    } else {
                        const response = await fetch(el.src);
                        const blob = await response.blob();
                        const file = new File([blob], "data", { type: blob.type });
                        attachment = await snikketService.prepareAttachment(file);
                    }
                    attachments[conversation] = (attachments[conversation] || []).concat([{ attachment: attachment, uri: el.src }]);
                    setAttachments({ ...attachments });
                })();
            } else if (el instanceof Element) {
                html += el.outerHTML;
            } else {
                html += el.textContent;
            }
        });
        html = html.replace(/^\u200b/, "");
        setCurrentMessage(html === "<br>" ? "" : html);
        if ( activeConversation ) {
            sendTyping({
                conversationId: activeConversation?.id,
                isTyping: true,
                userId: currentUser.id,
                content: textContent, // Note! Most often you don't want to send what the user types, as this can violate his privacy!
                throttle: true
            });
        }

    }

    const handleSend = (innerHTML:string, textContent:string, innerText:string, content:NodeList) => {
        if (!activeConversation) return

        const message = new ChatMessage({
            id: uuidv7(),
            content: innerText.replace(/\n$/, "").replace(/^\u200b/, "") as unknown as TextContent,
            contentType: MessageContentType.TextPlain,
            sender: new Sender({ id: currentUser.id, type: SenderType.User }),
            direction: MessageDirection.Outgoing,
            status: MessageStatus.Sent,
            data: { threadId: currentThread[activeConversation.id], attachments: (attachments[activeConversation?.id] || []).map((x) => x.attachment), replyToMessage: replyTo?.data } as any,
        });

        if (editing) {
            snikketService.correctMessage({ editing, message, conversationId: activeConversation.id });
            setCurrentMessage("");
            setEditing(null);
        } else {
            sendMessage({
                message,
                conversationId: activeConversation.id,
                senderId: currentUser.id,
                generateId: false,
            });
        }

        clearAttachments();
        setReplyTo(null);
    };

    const handleAttach = () => {
        const conversation = activeConversation?.id;
        if (!conversation) return;
        const input = document.createElement("input");
        input.type = "file";
        input.multiple = true;
        input.addEventListener("change", () => {
            if (input.files.length < 1) return; // Nothing selected
            for (var i = 0; i < input.files.length; i++) {
                const file = input.files[i];
                snikketService.prepareAttachment(file).then((attachment) => {
                    attachments[conversation] = (attachments[conversation] || []).concat([{ attachment: attachment, uri: URL.createObjectURL(file) }]);
                    setAttachments({ ...attachments });
                });
            }
        });
        input.click();
    };

    const removeAttachment = (toRemove) => {
        const conversation = activeConversation?.id;
        if (!conversation) return;
        URL.revokeObjectURL(toRemove.uri);
        setAttachments({ ...attachments, [conversation]: (attachments[conversation] || []).filter((attachment) => attachment.uri !== toRemove.uri)});
    };

    const clearAttachments = () => {
        const conversation = activeConversation?.id;
        if (!conversation) return;
        for (const attachment of attachments[conversation] || []) {
            URL.revokeObjectURL(attachment.uri);
        }
        setAttachments({ ...attachments, [conversation]: [] });
    }

    const editMessage = (m) => {
        const content = m.contentType === MessageContentType.TextPlain ? escapeHtml(m.content) : m.data?.text;
        setCurrentMessage(content);
        attachments[activeConversation?.id] = (m.data?.attachments || []).map((attachment) => ({ attachment: attachment, uri: attachment.uris[0] }));
        setAttachments(attachments);
        setEditing(m.data?.localId ?? m.id);
    };

    const messageContent = (m: ChatMessage<any, snikket.ChatMessage>) => {
        return [
            m.contentType === MessageContentType.TextHtml && <Message.HtmlContent html={DOMPurify.sanitize(m.content.body)} />,
            m.contentType === MessageContentType.TextPlain && <Message.TextContent text={m.content} />,
            m.data?.attachments?.map((attachment) => attachmentContent(attachment))
        ];
    };

    const toggleReaction = (m, reaction) => {
        if ((m.data.reactions.get(reaction) || []).includes(currentUser.id)) {
            activeConversation.data.chat.removeReaction(m.data, reaction);
        } else {
            activeConversation.data.chat.addReaction(m.data, reaction);
        }
    };

    document.querySelectorAll(".cs-message__html-content img").forEach((img:HTMLImageElement) => { if (img.naturalHeight === 0) { img.src = img.src; }});

    const renderConversations = search ? conversationSearch.search(search) : conversations.map(c => ({item: c}));
    return (<MainContainer responsive>
        <Sidebar position="left" scrollable={false} className={showSidebar ? "active" : ""}>
            <ConversationHeader style={{backgroundColor:"#fff"}}>
                <AvatarWithPlaceholder as={Avatar} displayName={currentUser.username} placeholderUri={currentUser.data?.placeholderUri} photoUri={currentUser.avatar} status={currentUser.presence.status == UserStatus.Unknown || currentUser.presence.status == UserStatus.Unavailable ? "unavailable" : "available"} />
                <ConversationHeader.Content>
                    {currentUser.username}
                </ConversationHeader.Content>
                <ConversationHeader.Actions>
                    <Button icon={<FontAwesomeIcon icon={faHouse} onClick={e => setActiveConversation(null)} />} />
                    <Button icon={<FontAwesomeIcon icon={faRightFromBracket} onClick={e => logout()} />} />
                </ConversationHeader.Actions>
            </ConversationHeader>
            <Search placeholder="Search..." value={search} onChange={setSearch} onClearClick={() => setSearch("")} />
            {search && search.length > 0 && <Conversation name={"Search messages for"} info={search}>
                    <Conversation.Operations visible>
                        <Button icon={<FontAwesomeIcon icon={faMagnifyingGlassArrowRight} />} onClick={e => { setActiveConversation(null); setSearchMessages(true); } } />
                    </Conversation.Operations>
            </Conversation>}
            {search && search === availableChats.q && availableChats.chats.filter(result => !getConversation(result.chatId)).map(result =>
                <Conversation key={result.chatId} name={result.fn} info={result.note}>
                    <Conversation.Operations visible>
                        {!result.caps.isChannel(result.chatId) && <AddUserButton onClick={e => snikketService.startChat(result)} />}
                        {result.caps.isChannel(result.chatId) && <ArrowButton direction="right" onClick={e => snikketService.startChat(result)} />}
                    </Conversation.Operations>
                </Conversation>
            )}
            <Virtuoso totalCount={renderConversations.length} itemContent={index => {
                const c = renderConversations[index].item;
                // Helper for getting the data of the first participant
                const info = conversationInfo(c);

                return <Conversation key={c.id}
                              name={info.fn}
                              info={info.info}
                              active={activeConversation?.id === c.id}
                              unreadCnt={c.unreadCounter}
                              onClick={() => setActiveConversation(c.id)}>
                    <AvatarWithPlaceholder as={Avatar} photoUri={info.photo} placeholderUri={info.placeholder} displayName={info.fn} />
                    <Conversation.Operations visible>
                        <Button icon={<FontAwesomeIcon icon={faXmark} />} onClick={e => c.data.chat?.close()} />
                    </Conversation.Operations>
                </Conversation>
            }} />
        </Sidebar>

       { activeConversation && activeConversation.data.chat.videoTracks().length > 0 && (
        <div className="container"><div className="row">
        {activeConversation && activeConversation.data.chat.videoTracks().map((track) =>
            <div className="col">
                <div className="embed-responsive embed-responsive-4by3">
                    <video className="embed-responsive-item" key={track.id} ref={video => { if (video) { video.srcObject = new MediaStream([track]); video.play(); } }}></video>
                </div>
            </div>
         )}
        </div></div>
       )}

        <ChatContainer className={showSidebar ? "" : "active"}>
            {<ConversationHeader>
                <ConversationHeader.Back onClick={handleBackClick} />
                {activeConversation && <AvatarWithPlaceholder as={Avatar} photoUri={activeConversationInfo?.photo} placeholderUri={activeConversationInfo?.placeholder} displayName={activeConversationInfo?.fn} />}
                <ConversationHeader.Content userName={searchMessages ? "Search Results" : activeConversationInfo?.fn} />
                {activeConversation && <ConversationHeader.Actions>
                    {activeConversation.data.chat.dtmf() && <Button icon={<FontAwesomeIcon icon={faHashtag} onClick={e => setShowDtmf(true)} />} />}
                    {activeConversation.data.chat.callStatus() === "ongoing" && <Button icon={<FontAwesomeIcon icon={faPhoneSlash} onClick={e => activeConversation.data.chat.hangup()} />} />}
                    {activeConversation.data.chat.callStatus() === "ongoing" && <Button icon={<FontAwesomeIcon icon={faDesktop} onClick={e => navigator.mediaDevices.getDisplayMedia().then((stream) => activeConversation.data.chat.addMedia([stream]))} />} />}
                    {activeConversation.data.chat.callStatus() === "incoming" && <Button icon={<FontAwesomeIcon icon={faPhoneVolume} onClick={e => { activeConversation.data.chat.acceptCall(); snikketService.stopCallRinging(); } } />} />}
                    {activeConversation.data.chat.callStatus() === "none" && activeConversation.data.chat.canAudioCall() && <Button icon={<FontAwesomeIcon icon={faPhoneAlt} onClick={e => activeConversation.data.chat.startCall(true, false) } />} />}
                    {activeConversation.data.chat.callStatus() === "none" && activeConversation.data.chat.canVideoCall() && <Button icon={<FontAwesomeIcon icon={faVideo} onClick={e => activeConversation.data.chat.startCall(true, true) } />} />}
                </ConversationHeader.Actions>}
            </ConversationHeader>}
            {!activeConversation && !searchMessages && <MessageList><MessageList.Content><form onSubmit={ e => { e.preventDefault(); snikketService.xmppClient.setDisplayName((e.target as any).fn.value); } }>Display name: <input key={"nicknameWithDefault" + snikketService.xmppClient.displayName()} type="text" name="fn" autoComplete="nickname" defaultValue={snikketService.xmppClient.displayName()} /><button>Save</button></form></MessageList.Content></MessageList>}
            {(activeConversation || searchMessages) && <div {...({} as any)} as={MessageList} className="cs-message-list"><Virtuoso key={`message-list-${searchMessages ? "search" : activeConversation.id}-${jumpTo}`} ref={messageList} followOutput={true} alignToBottom={true} startReached={onYReachStart} endReached={onYReachEnd} rangeChanged={range => { if(activeConversation) activeConversation.data = {...activeConversation?.data, scrollRange: {...range} } } } firstItemIndex={Number.MAX_SAFE_INTEGER - displayMessageGroups.length} initialTopMostItemIndex={jumpTo ? jumpTo : (activeConversation?.data?.scrollRange?.startIndex || -1) >= 0 ? (displayMessageGroups.length - (Number.MAX_SAFE_INTEGER - (activeConversation?.data?.scrollRange?.startIndex || 0))) : displayMessageGroups.length - 1} data={displayMessageGroups} itemContent={(index, g) => <MessageGroup key={g.id} direction={g.direction} sender={g.sender?.id}>
                    {(!activeConversation || activeConversation.participants.length > 1 || (g.sender?.id !== activeConversation.id && g.direction == MessageDirection.Incoming)) && <AvatarWithPlaceholder as={Avatar} photoUri={getUser(g.sender?.id)?.avatar} placeholderUri={getUser(g.sender?.id)?.data?.placeholderUri} displayName={getUser(g.sender?.id)?.username} onClick={() => avatarClick(g)} />}
                    <MessageGroup.Messages>
                        {g.messages.map((m:ChatMessage<MessageContentType, any>) => <Message onClick={() => setSelectedThread(m.data?.threadId)} key={m.id} model={{
                            direction: m.direction,
                            position: "normal"
                        }}>
                            { activeConversation && <Message.Header {...({} as any)} ref={(el) => toolbarRefs.current[activeConversation.id + m.id] = el}>
                                <div className={`toolbar ${reactionPopovers[activeConversation.id + m.id] ? "active" : ""}`} data-id={m.id}>
                                    {m.direction === MessageDirection.Outgoing && <Button icon={<FontAwesomeIcon icon={faPenToSquare} onClick={e => editMessage(m)} />} />}
                                    <Button icon={<FontAwesomeIcon icon={faReply} onClick={e => { setReplyTo(m); setSelectedThread(m.data?.threadId); }} />} />
                                    <Popover isOpen={reactionPopovers[activeConversation.id + m.id]} parentElement={toolbarRefs.current[activeConversation.id + m.id] || window.document.body} onClickOutside={e => setReactionPopovers({...reactionPopovers, [activeConversation.id + m.id]: false})} align="start" positions={["bottom", "top"]} containerStyle={{zIndex: "1000"}} content={
                                        <Picker data={emojiData} onEmojiSelect={emoji => {toggleReaction(m, emoji.native); setReactionPopovers({...reactionPopovers, [activeConversation.id + m.id]: false})}} />
                                    }>
                                        <span><Button icon={<FontAwesomeIcon icon={faFaceSmile} onClick={e => setReactionPopovers({...reactionPopovers, [activeConversation.id + m.id]: !reactionPopovers[activeConversation.id + m.id]})} />} /></span>
                                    </Popover>
                                </div>
                            </Message.Header> }
                            <Message.CustomContent>
                                { searchMessages && <Button border onClick={e => snikketService.jumpTo(m.data?.chatId(), m.id, m.data.timestamp)}>jump</Button> }
                                {m.data?.replyToMessage && <blockquote className="u-in-reply-to">
                 {messageContent(
                             new ChatMessage({
            id: m.data.replyToMessage.serverId || m.data.replyToMessage.localId, // Sometimes we don't know the serverId of a sent message
            status: MessageStatus.DeliveredToDevice,
            sender: new Sender({ id: m.data.replyToMessage.senderId(), type: SenderType.User }),
            direction: m.data.replyToMessage.isIncoming() ? MessageDirection.Incoming : MessageDirection.Outgoing,
            content: { body: m.data.replyToMessage.html() },
            contentType: MessageContentType.TextHtml,
            createdTime: new Date(m.data.replyToMessage.timestamp),
            data: m.data.replyToMessage
                 }))}
                                </blockquote>}
                                {messageContent(m)}
                                {[...(m.data?.reactions?.entries() || [])].map(([reaction, senders]) => <Button border onClick={e => toggleReaction(m, reaction)} key={m.id + ":reaction:" + reaction} className={senders.includes(currentUser.id) ? "reaction mine" : "reaction"} title={senders.join("\n")}>{reaction + " " + senders.length}</Button>)}
                                {m.data?.threadId && m.data?.threadIcon && <img src={m.data.threadIcon()} alt="thread indicator" style={{height: "0.75em", float: "right"}} />}
                                {m.data?.timestamp && <time dateTime={m.data?.timestamp}>{new Date(m.data?.timestamp).toLocaleString([], { dateStyle: "short", timeStyle: "short" })}</time>}
                            </Message.CustomContent>
                            {m.direction === MessageDirection.Outgoing && <Message.Footer style={{display: "block"}}>{statusStrings[m.status]}</Message.Footer>}
                            {m.data?.versions?.length > 0 && <Message.Footer style={{display: "block"}}>Edited {m.data.versions.length} times</Message.Footer>}
                        </Message>)}
                    </MessageGroup.Messages>
                    {(!activeConversation || activeConversation.participants.length > 1 || g.sender?.id !== activeConversation.id) && g.direction === MessageDirection.Incoming && <MessageGroup.Footer>{getUser(g.sender?.id)?.username}</MessageGroup.Footer>}
                </MessageGroup>} />
                {getTypingIndicator()}
            </div>}

            <div as={MessageInput} style={{borderTop: "1px solid #d1dbe3"}} {...({} as any)}>
                {replyTo && <div style={{borderBottom: "2px dotted #d1dbe3", padding: "1em"}}>
                    {replyTo.contentType === MessageContentType.TextHtml && <Message.HtmlContent html={DOMPurify.sanitize(replyTo.content.body)} />}
                    {replyTo.contentType === MessageContentType.TextPlain && <Message.TextContent text={replyTo.content} />}
                    {replyTo.data?.attachments?.map((attachment) => attachmentContent(attachment, "5em"))}
                </div>}
                {(attachments[activeConversation?.id] || []).map((attachment) => <img key={attachment.attachment.uri} src={attachment.uri} alt={attachment.attachment.name} style={{maxHeight: "3em"}} onClick={() => removeAttachment(attachment)} />)}
                <div>
                    {replyTo && <Button onClick={(e) => { setReplyTo(null); setSelectedThread(null); }}>cancel reply</Button>}
                    {editing && <Button onClick={(e) => { setEditing(null); setCurrentMessage(""); clearAttachments(); }}>cancel editing</Button>}
                </div>
	            <div style={{display: "flex", flexDirection: "row"}}>
		            <Button onClick={() => setSelectedThread(uuidv7())} disabled={!activeConversation} border title="Thread selector" icon={<img src={currentThread[activeConversation?.id] ? snikket.Identicon.svg(currentThread[activeConversation?.id]) : "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' height='0' width='0'/>"} alt="Thread selector" style={{width: "1em"}} />} />
                    <MessageInput ref={inputRef} value={currentMessage || "\u200b"} onChange={handleChange} sendDisabled={currentMessage.length === 0 && (attachments[activeConversation?.id] || []).length === 0} onSend={handleSend} disabled={!activeConversation} onAttachClick={handleAttach} attachButton={true} placeholder="Send message" style={{flex: 1, borderTop: 0, flexShrink: "initital"}} />
               </div>
            </div>
        </ChatContainer>

        {audioTracks.map((data) =>
            <audio key={data.chatId + "/" + (data.streams[0]?.id || "")} ref={audio => { if (audio) { audio.srcObject = new MediaStream([data.track]); audio.play(); } }}></audio>
        )}

        <Modal size="sm" aria-labelledby="contained-modal-title-vcenter" centered show={showDtmf} onHide={() => setShowDtmf(false)}>
           <Modal.Body>
               <div>
                   <Button icon={<FontAwesomeIcon icon={fa1} onClick={e => oneDtmf("1")} />} />
                   <Button icon={<FontAwesomeIcon icon={fa2} onClick={e => oneDtmf("2")} />} />
                   <Button icon={<FontAwesomeIcon icon={fa3} onClick={e => oneDtmf("3")} />} />
               </div>
               <div>
                   <Button icon={<FontAwesomeIcon icon={fa4} onClick={e => oneDtmf("4")} />} />
                   <Button icon={<FontAwesomeIcon icon={fa5} onClick={e => oneDtmf("5")} />} />
                   <Button icon={<FontAwesomeIcon icon={fa6} onClick={e => oneDtmf("6")} />} />
               </div>
               <div>
                   <Button icon={<FontAwesomeIcon icon={fa7} onClick={e => oneDtmf("7")} />} />
                   <Button icon={<FontAwesomeIcon icon={fa8} onClick={e => oneDtmf("8")} />} />
                   <Button icon={<FontAwesomeIcon icon={fa9} onClick={e => oneDtmf("9")} />} />
               </div>
               <div>
                   <Button icon={<FontAwesomeIcon icon={faStarOfLife} onClick={e => oneDtmf("*")} />} />
                   <Button icon={<FontAwesomeIcon icon={fa0} onClick={e => oneDtmf("0")} />} />
                   <Button icon={<FontAwesomeIcon icon={faHashtag} onClick={e => oneDtmf("#")} />} />
               </div>
           </Modal.Body>
       </Modal>

    </MainContainer>);
    
}
